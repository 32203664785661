<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submit"> Сохранить  </el-button>
      <el-button type="primary" @click="submitAndExit"> Сохранить и вернуться </el-button>
    </div>
    <el-form
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="Фото" prop="images">
        <Uploader
          :limit="10"
          :files="form?.images ?? []"
          multiple
          @upload="uploadImages"
        />
      </el-form-item>
      <el-form-item label="Имя" prop="name">
        <el-input v-model="form.name" @input="handleNameInput"></el-input>
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="Описание" prop="description">
        <TextEditor :value.sync="form.description" />
      </el-form-item>
      <el-form-item label="Продукты" prop="products">
        <el-select
          v-model="form.products"
          v-el-select-loadmore="loadMoreProducts"
          value-key="id"
          filterable
          clearable
          remote
          multiple
          :remote-method="searchProducts"
          :loading="productsLoading"
          placeholder="Выбрать"
        >
          <el-option
            v-for="item in products"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Теги" prop="tags">
        <el-select
          v-model="form.tags"
          v-el-select-loadmore="loadMoreTags"
          value-key="id"
          filterable
          clearable
          remote
          multiple
          :remote-method="searchTags"
          :loading="tagsLoading"
          placeholder="Выбрать"
        >
          <el-option
            v-for="item in tags"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <SeoBlock
        is-with-keywords
        is-add-event
        has-set-default-flag
        :setDefault.sync="form.seo.setDefault"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage.origin"
        :keywords.sync="form.seo.keywords"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import regexp from '@/helpers/regexp.js'

import { transliterate } from '@/helpers/slug'
import { debounce } from '@/helpers/debounce.js'
import { SEO_TYPES } from '@/constants/seo'

import Uploader from '@/components/moleculs/Uploader.vue'
import TextEditor from '@/components/atoms/TextEditor.vue'
import SeoBlock from '@/components/moleculs/SEO.vue'

export default {
  components: {
    Uploader,
    TextEditor,
    SeoBlock,
  },
  SEO_TYPES,
  mixins: [notifications],
  data() {
    return {
      tags: [],
      tagsLoading: false,
      tagsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
      },
      products: [],
      productsLoading: false,
      productsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
      },
      form: {
        images: [],
        name: '',
        slug: '',
        description: '',
        products: null,
        tags: null,
        seo: {
          setDefault: false,
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: {
            id: null,
            caption: '',
            origin: '',
            order: 1,
          },
          keywords: '',
        },
      },
      rules: {
        images: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        description: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        products: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          keywords: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: {
            origin: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
          },
        },
      },
    }
  },
  async created() {
    await Promise.all([this.getSolution(), this.getTags(), this.getProducts()])
    this.tagsSearch = debounce(this.getTags, 500)
    this.productsSearch = debounce(this.getProducts, 500)
  },
  methods: {
    async getSolution() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.SolutionsActions.getOne(
          this.$route.params.id,
        )

      loading.close()

      if (error) {
        return
      }

      this.form = {
        ...value,
        images: value?.images ?? [],
        seo: value?.seo
          ? {
              ...value?.seo,
              setDefault: value?.seo?.type === SEO_TYPES.TEMPLATE,
              ogImage: value?.seo?.ogImage
                ? {
                    ...value?.seo?.ogImage,
                    origin: value?.seo?.ogImage?.original,
                  }
                : this.form?.seo?.ogImage,
            }
          : {
              ...this.form?.seo,
              setDefault: false,
            },
      }
    },
    async getTags() {
      this.tagsLoading = true

      const { value, error } =
        await delivery.AddeventServiceCore.TagsActions.getList(this.tagsOptions)
      this.tagsLoading = false

      if (error) return

      this.tags = [...this.tags, ...value.data]
      this.tagsOptions.total = value.meta.count
    },
    async getProducts() {
      this.productsLoading = true

      const { value, error } =
        await delivery.AddeventServiceCore.ProductsActions.getList(
          this.productsOptions,
        )
      this.productsLoading = false

      if (error) return

      this.products = [...this.products, ...value.data]
      this.productsOptions.total = value.meta.count
    },
    async searchProducts(search) {
      this.productsOptions = {
        ...this.productsOptions,
        page: 1,
        search,
      }
      this.productsLoading = true
      this.products = []
      await this.productsSearch()
    },
    async searchTags(search) {
      this.tagsOptions = {
        ...this.tagsOptions,
        page: 1,
        search,
      }
      this.tagsLoading = true
      this.tags = []
      await this.tagsSearch()
    },
    loadMoreTags() {
      const { page, limit, total } = this.tagsOptions
      if (limit * page < total) {
        this.tagsOptions.page++
        this.getTags()
      }
    },
    loadMoreProducts() {
      const { page, limit, total } = this.productsOptions
      if (limit * page < total) {
        this.productsOptions.page++
        this.getProducts()
      }
    },
    async submit() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(async (valid) => {
          if (!valid) return reject()

          const data = {
            ...this.form,
            products: this.form.products?.map((item) => item.id),
            tags: this.form.tags?.map((item) => item.id),
            images:
              this.form.images?.map((e) => ({
                id: e?.id ?? null,
                caption: e.caption,
                origin: e.original,
                order: e.order_field ?? 1,
              })) ?? [],
            setDefault: this.form?.seo?.setDefault,
            seo: this.form?.seo?.setDefault
              ? null
              : {
                  ...this.form.seo,
                  type: SEO_TYPES.DEFAULT,
                  ogImage: {
                    ...this.form?.seo?.ogImage,
                    caption: this.form?.seo?.title,
                  },
                },
          }

          const loading = this.$loading({
            lock: true,
          })

          const { error } =
            await delivery.AddeventServiceCore.SolutionsActions.update(
              this.$route.params.id,
              data,
            )

          loading.close()

          if (error) {
            this.showNotification('Ошибка создания категории', 'error')
            return reject()
          }

          this.showNotification('Категория успешно создана', 'success')
          resolve()
        })
      })
},
    async submitAndExit() {
      await this.submit()
      this.$router.go(-1)
    },
    uploadImages(images) {
      this.form.images = images
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    margin-bottom: 1rem;
    z-index: 999;
  }

  .form {
    & > div > label {
      text-align: left;
    }

    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }
  }
}
</style>
